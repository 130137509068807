import HttpHandler from "@/handler/HttpHandler";
import HttpConstant from "@/const/HttpConstant";

export default class Nas {
    constructor() {
        this.http = new HttpHandler();
    }

    getFiles(data) {
        let url = '/api/nas/file/list';
        return this.http.post(url, data, HttpConstant.APPLICATION_JSON_REQ);
    }

    refreshToken(data) {
        let url = '/api/nas/refreshToken';
        return this.http.get(url, data, HttpConstant.FORM_DATA_REQ);
    }

    getToken() {
        let url = '/api/nas/getToken';
        return this.http.get(url, null, HttpConstant.FORM_DATA_REQ);
    }

    fileLists(data, url) {
        return this.http.get(url, data, HttpConstant.FORM_DATA_REQ);
    }
}