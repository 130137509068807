<template>
  <div class="file-item">
    <div  class="file">
      <el-image v-if="isFolder" :src="require('@/assets/image/folder.png')" :alt="name" lazy></el-image>
      <el-image v-else :src="thumb" :alt="name" lazy></el-image>
      <div class="clamp" >{{ name }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: String,
    isFolder: Boolean,
    thumb: String,
  },
  methods: {
  }
};
</script>

<style>
.file-item {
  width: auto;
  /* more styles */
}
.folder {
  /* Folder-specific styles */
}
.file img {
  width: 120px;
  height: 75px;
  border-radius: 5px;
  /* more styles */
}
.clamp {
  font-size: 16px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1; /* 限制在两行内 */
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  word-wrap: break-word;
}
</style>
