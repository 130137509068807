<template>
  <div class="file-manager">
    <div style="margin: 25px 0">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item><span class="pointer" @click="loadFile(-1)">Nas</span></el-breadcrumb-item>
        <el-breadcrumb-item v-for="(item, index) in breadcrumbList" :key="index">
          <span class="pointer" @click="loadFile(index)">{{
              item
            }}</span>
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div v-loading="loading"
         element-loading-text="拼命加载中"
         element-loading-spinner="el-icon-loading"
         element-loading-background="rgba(0, 0, 0, 0.8)">
      <el-row>
        <el-col :span="spanNumber" v-for="(item, index) in items"
                :key="index" class="textCenter">
          <div @click="clickFun(item)">
            <FileItem
                :name="item.path"
                :isFolder="item.type === 32"
                :thumb="item.thumb"/>
          </div>
        </el-col>
      </el-row>
    </div>


    <el-dialog title="登录" :visible.sync="dialogFormVisible" width="100%">
      <el-form :model="form">
        <el-form-item label="账户">
          <el-input v-model="form.phone" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="密码">
          <el-input v-model="form.pwd" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="refreshToken">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import FileItem from '../../components/FileItem.vue';
import Nas from '@/request/nas.js'
import cryptoUtil from "@/utils/cryptoUtil";

export default {
  components: {
    FileItem
  },
  data() {
    return {
      dialogFormVisible: false,
      form: {
        phone: "",
        pwd: ""
      },
      ask: new Nas(),
      spanNumber: 0,
      listParam: {
        path: "/.ugreen_nas/366858",
        uuid: "57c3ec6b-fb4a-4fa9-b53c-f6685992baeb",
        page: 1,
        size: 256,
        order: 5,
        type: 0,
        ver: 2
      },
      items: [],
      breadcrumbList: [],
      loginUser: null,
      loading: false
    };
  },
  created() {
    this.getSpan()
    window.onresize = () => {
      this.getSpan()
    };
  },
  mounted() {
    this.ask.getToken().then(res => {
      if (res.data == null) {
        this.dialogFormVisible = true

      } else {
        this.loginUser = res.data
        this.getFiles()
      }
    })
  },
  methods: {
    loadFile(index) {
      if (this.breadcrumbList.length === index + 1) {
        return
      } else if (index === -1) {
        this.listParam.path = "/.ugreen_nas/366858"
        this.breadcrumbList = []
      } else {
        this.listParam.path = "/.ugreen_nas/366858/" + this.breadcrumbList.slice(0, index + 1).join("/")
        this.breadcrumbList = this.breadcrumbList.slice(0, index + 1)
      }
      this.getFiles()
    },
    refreshToken() {
      this.ask.refreshToken(this.form).then(res => {
        this.loginUser = res.data
        this.getFiles()
        this.dialogFormVisible = false
      })
    },
    getFiles() {
      if (this.loginUser == null) {
        this.refreshToken()
      }
      this.listParam['api_token'] = this.loginUser['api_token']
      let url = `/nasApi/${this.loginUser.deviceAlias}/v1/file/list`
      this.loading = true;
      this.ask.fileLists(this.listParam, url).then(res => {
        if (res.code == 401 || res.code == 8024) {
          this.refreshToken()
          this.dialogFormVisible = true
        }
        res.data.list.forEach(item => {
          if (item.type === 1 || item.type === 8) {
            item['thumb'] = `/nasApi/${this.loginUser.deviceAlias}/v1/file/http_download?uuid=57c3ec6b-fb4a-4fa9-b53c-f6685992baeb&path=/.thumb${item.path}-SMALL.png&mtime=1713002140&ctime=&api_token=${this.loginUser.api_token}`
          } else {
            item['thumb'] = require('@/assets/image/fileImg.png')
          }
          item.path = item.path.substring(item.path.lastIndexOf('/') + 1);
        });
        this.items = res.data.list
        this.loading = false;
      }).catch(e => {
        this.loading = false;
      })
    },

    clickFun(item) {
      if (item.type === 32) {
        this.breadcrumbList.push(item.path);
        let path = "/.ugreen_nas/366858/" + this.breadcrumbList.join("/")
        this.listParam.path = path
        this.getFiles();
      } else {
        let path = "/.ugreen_nas/366858/" + this.breadcrumbList.join("/")
        let textToCopy = `${this.loginUser.dispatchDomain}/${this.loginUser.deviceAlias}/v1/file/http_download?uuid=57c3ec6b-fb4a-4fa9-b53c-f6685992baeb&path=${path}/${item.path}&ctime=&api_token=${this.loginUser.api_token}`
        const textArea = document.createElement('textarea');
        textArea.value = textToCopy;
        document.body.appendChild(textArea);
        textArea.select();
        let encryptUrl = escape(textToCopy)
        try {
          document.execCommand('copy');
          this.$notify({
            title: '提示',
            dangerouslyUseHTMLString: true,
            message: `<a href="/player?encipher=0&url=${encryptUrl}" target="_blank"><span>资源地址已复制: </span> <strong>${item.path}</strong></a></div>`
          });
        } catch (err) {
          this.$message({
            showClose: true,
            message: '无法复制文本到剪贴板',
            type: 'error'
          });
        }

        document.body.removeChild(textArea);
      }
    },
    getSpan() {
      const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      if (width < 250) {
        this.spanNumber = 24; // 小屏幕，每行显示1张图片
      } else if (width < 550) {
        this.spanNumber = 12; // 小屏幕，每行显示2张图片
      } else if (width < 900) {
        this.spanNumber = 6; // 中等屏幕，每行显示3张图片
      } else if (width < 1024) {
        this.spanNumber = 4; // 大屏幕，每行显示4张图片
      } else if (width < 1550) {
        this.spanNumber = 3; // 大屏幕，每行显示4张图片
      } else {
        this.spanNumber = 2; // 大屏幕，每行显示4张图片
      }
    }
  }
};
</script>

<style>
.textCenter {
  text-align: center;
  height: 100px;
}

.pointer {
  cursor: pointer;
}
</style>
